<template>
  <div class="ApplicationLibraryPlatformForm-wrapper">
    <application-library-form v-bind="params" />
  </div>
</template>

<script>
import ApplicationLibraryForm from '../applicationLibraryForm/ApplicationLibraryForm'

export default {
  name: 'ApplicationLibraryPlatformForm',
  components: {
    ApplicationLibraryForm
  },
  data () {
    return {
      params: {
        type: 'platform',
        appURL: this.$route.query.appURL
      }
    }
  }
}
</script>
